    import * as React from 'react'
    import { useTheme, styled } from '@mui/material/styles'
    import {
        Grid,
        Typography,
        Container,
        Button,
        useMediaQuery,
        TextField,
        Modal,
        Box,
        Select,
        MenuItem,
        InputLabel,
        FormControl,
        IconButton,
        OutlinedInput,
        InputAdornment,
        Collapse,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Paper
    } from '@mui/material'
    import XLSX from  'sheetjs-style'
    import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
    import Tooltip from '@mui/material/Tooltip';
    import { Search } from '@mui/icons-material'
    import CheckCircleIcon from '@mui/icons-material/CheckCircle';
    import CancelIcon from '@mui/icons-material/Cancel';
    import DeleteIcon from '@mui/icons-material/Delete';
    import FilePresentIcon from '@mui/icons-material/FilePresent'
    import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
    import { useNavigate } from "react-router-dom"
    import { useSelector } from 'react-redux'
    import { modeContext } from '../../context/modeContext.js'
    import { gridSpacing } from '../../store/constant.js'
    import { listarParametros } from '../../services/Parametro.js'
    import PickArticle from '../shared/article/PickArticle.js' 
    import DataGridApp from '../../ui-component/grid/DataGridApp'
    import StatsCard from '../../ui-component/cards/StatsCard.js'
    import MainCard from '../../ui-component/cards/MainCard.js'
    import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
    import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
    import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
    import esLocale from 'date-fns/locale/es'
    import SearchIcon from '@mui/icons-material/Search'
    import PickSerie from '../shared/serie/PickSerie.js'
    import LinkIcon from '@mui/icons-material/Link';
    import { Alert } from '@mui/material';
    import { IconFileDownload } from '@tabler/icons';

    // Estilos para las tarjetas
    const CardWrapper = styled(MainCard)(({ theme }) => ({
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#fafafa'
    }))

    const MantenimientoMaquinarias = () => {
        const theme = useTheme()
        const customization = useSelector((state) => state.customization)
        const { token, tokenCompany } = React.useContext(modeContext)
        const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
        const navigate = useNavigate()
        const [openErrorModal, setOpenErrorModal] = React.useState(false);
        const [errorModalMessage, setErrorModalMessage] = React.useState('');
        const [openModal, setOpenModal] = React.useState(false)
        const [openRegistrarModal, setOpenRegistrarModal] = React.useState(false);
        const [selectedRowData, setSelectedRowData] = React.useState(null);
        const [selectedTechnician, setSelectedTechnician] = React.useState('');
        const [inspectionDate, setInspectionDate] = React.useState(new Date());
        const [openModalAprobar, setOpenModalAprobar] = React.useState(false);
        const [openModalCancelar, setOpenModalCancelar] = React.useState(false);
        const [openModalRechazar, setOpenModalRechazar] = React.useState(false);
        const [comentarioRechazo, setComentarioRechazo] = React.useState('');
        const [selectedRow, setSelectedRow] = React.useState(null);
        
        const [tipoMantenimiento, setTipoMantenimiento] = React.useState('')
        const [tipoAlmacenNuevo, setTipoAlmacenNuevo] = React.useState('Todos'); 
        const [tipoAlmacen, setTipoAlmacen] = React.useState('')
        const [cuenta, setCuenta] = React.useState('')
        const [almacen, setAlmacen] = React.useState('')
        const [articulo, setArticulo] = React.useState({
            CodArticle: '',
            IdArticle: '',
            Article: '',
            IdAlmacen:''
        })
        const [serie, setSerie] = React.useState({
            IdControlSerie: null,
            SerialNumber: '',
            Placa: ''
        });
        
        const [openSerie, setOpenSerie] = React.useState(false);
        
        const [comentarios, setComentarios] = React.useState('')
        const [comentariosRegistro, setComentariosRegistro] = React.useState('')
        const [archivo, setArchivo] = React.useState(null)
        const [attachedFile, setAttachedFile] = React.useState(null);
        const [disabledCuenta, setDisabledCuenta] = React.useState(false)

        const [tipoAlmacenOptions, setTipoAlmacenOptions] = React.useState([])
        const [almacenOptions, setAlmacenOptions] = React.useState([])

        const [openFind, setOpenFind] = React.useState(false)
        const [disabledArticle, setDisabledArticle] = React.useState(true)
        const [estadoMantenimiento, setEstadoMantenimiento] = React.useState('');
        const [placa, setPlaca] = React.useState('');
        const [cuentaOptions, setCuentaOptions] = React.useState([]);
        const [cuentaOptionsFiltro, setCuentaOptionsFiltro] = React.useState([]);
        const [dateInit, setDateInit] = React.useState(new Date())
        const [dateFin, setDateFin] = React.useState(new Date())
        const [almacenesCargados, setAlmacenesCargados] = React.useState(false);
        const [errorMessage, setErrorMessage] = React.useState(null);
        const columnsComentarios = [
            { field: 'FechaRegistro', headerName: 'Fecha Registro', width: 150 },
            { field: 'Comentarios', headerName: 'Comentario', width: 350 },
            { field: 'EstadoMantenimiento', headerName: 'Evento mantenimiento', width: 150 }
        ];
        
        const [stats, setStats] = React.useState({
            "NO_INICIADO": 0,
            "PROGRAMADO": 0,
            "EN_PROCESO": 0,
            "PENDIENTE_POR_CONFIRMAR": 0,
            "EJECUTADO": 0,
            "TODOS":0,
        })

        const [heades, setHeades] = React.useState([])
        const [openRowIndex, setOpenRowIndex] = React.useState(null)
        const [openWarningModal, setOpenWarningModal] = React.useState(false);
        const [openModalComentarios, setOpenModalComentarios] = React.useState(false);
        const [detallesComentarios, setDetallesComentarios] = React.useState([]);


        const handleOpenFindModalComentarios = async (openModalComentarios, IdDetalleMantenimiento, parametroNombre, comentarioFinalizado = null, estadoSolicitud=null) => {
            try {
                console.log("COMENTARIO FINALIZADO +" + comentarioFinalizado)
                if (comentarioFinalizado) {
                    setDetallesComentarios([{
                        FechaRegistro: new Date().toLocaleDateString(), 
                        Comentarios: comentarioFinalizado,
                        EstadoMantenimiento: estadoSolicitud
                    }]);
                    setOpenModalComentarios(openModalComentarios);
                    return;
                }
                else{
                const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/mantenimientos/detalle/${IdDetalleMantenimiento}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany,
                        'Cache-Control': 'no-cache', 
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    }
                });
                const data = await response.json();
        
                if (Array.isArray(data)) {
                    const dataConParametro = data.map(detalle => ({
                        ...detalle,
                        parametroNombre, 
                    }));
                    setDetallesComentarios(dataConParametro);
                } else {
                    setDetallesComentarios([{ ...data, parametroNombre }]);
                }
        
                setOpenModalComentarios(openModalComentarios);
            }} catch (error) {
                console.error('Error al obtener los detalles:', error);
            }
        };
        
        
        React.useEffect(() => {
            const fetchTipoAlmacenYTransferenciaOptions = async () => {
                try {
                    const resultTipoDeAlmacenes = await listarParametros(process.env.REACT_APP_ID_GRUPO_PARAMETRO_TIPO_ALMACENES, token, tokenCompany);
                    setTipoAlmacenOptions(resultTipoDeAlmacenes);
                } catch (error) {
                    console.error("Error al obtener los tipos de almacén:", error);
                }
            };
            fetchTipoAlmacenYTransferenciaOptions();
        }, [token, tokenCompany]);

        const fetchCuentasOptions = async () => {
            try {
                let urlNegocios = `${process.env.REACT_APP_API}business/api/tipo_negocio/TipoNegocioUsuario`;
                let urlProveedores = `${process.env.REACT_APP_API}business/api/tipo_negocio/TipoProveedorUsuario`;
        
                let cuentaOptionsMerged = [];
                let cuentaOptionsMergedFiltro = [];
        
                if (tipoAlmacen === "Almacen-Cliente") {
                    const tipoNegocios = await fetch(urlNegocios, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany,
                            'Cache-Control': 'no-cache', 
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        }
                    }).then((res) => res.json());
        
                    cuentaOptionsMerged = tipoNegocios.map((item) => ({ ...item, tipo: "negocio" }));
                } else if (tipoAlmacen === "Almacen-Proveedor") {
                    const proveedores = await fetch(urlProveedores, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany
                        }
                    }).then((res) => res.json());
        
                    cuentaOptionsMerged = proveedores.map((item) => ({ ...item, tipo: "proveedor" }));
                } else if (tipoAlmacen === "Todos") {
                    const [tipoNegocios, proveedores] = await Promise.all([
                        fetch(urlNegocios, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json()),
                        fetch(urlProveedores, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json())
                    ]);
        
                    cuentaOptionsMerged = [
                        ...tipoNegocios.map((item) => ({ ...item, tipo: "negocio" })),
                        ...proveedores.map((item) => ({ ...item, tipo: "proveedor" }))
                    ];
                }
                else if (tipoAlmacenNuevo==='Todos') {
                    const [tipoNegocios, proveedores] = await Promise.all([
                        fetch(urlNegocios, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json()),
                        fetch(urlProveedores, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                token: token,
                                empresa: tokenCompany
                            }
                        }).then((res) => res.json())
                    ]);
        
                    cuentaOptionsMergedFiltro = [
                        ...tipoNegocios.map((item) => ({ ...item, tipo: "negocio" })),
                        ...proveedores.map((item) => ({ ...item, tipo: "proveedor" }))
                    ];
                }
                setCuentaOptions(cuentaOptionsMerged);
                setCuentaOptionsFiltro(cuentaOptionsMergedFiltro)
            } 
            catch (error) {
                console.error("Error:", error);
            }
        };

        const fetchAlmacenes = async () => {
            let url = `${process.env.REACT_APP_API}business/api/almacen/getAlmacenEmpresa`;
        
            if (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente") {
                const tipoCuenta = tipoAlmacen === "Almacen-Proveedor" ? 'proveedor' : 'negocio';
                url += `?tipoDeAlmacen=${tipoAlmacen}&cuentaAreaId=${cuenta}&tipoCuenta=${tipoCuenta}`;
            }
            else if (tipoAlmacen === "Almacen-Empresa") {
                url += `?tipoDeAlmacen=${tipoAlmacen}`;
            }
        
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    cache: 'no-cache',
                    pragma: 'no-cache',
                    'cache-control': 'no-cache'
                }
            };
        
            try {
                const res = await fetch(url, options);
                const response = await res.json();
                setAlmacenOptions(response);
                setAlmacenesCargados(true);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        
        React.useEffect(() => {
            setCuenta('') 
            setAlmacen('') 
            setCuentaOptions([]) 
            setAlmacenOptions([]) 

            if (tipoAlmacen === "Almacen-Empresa") {
                setDisabledCuenta(true) 
                fetchAlmacenes()
            } else {
                setDisabledCuenta(false) 
            }
        }, [tipoAlmacen])

        React.useEffect(() => {
            if (tipoAlmacen === "Almacen-Empresa" || (cuenta && (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente" || tipoAlmacen === "Todos"))) {
                fetchAlmacenes()
            }
        }, [cuenta, tipoAlmacen])

        const handleSearch = () => {
            const query = {};
            
            if (estadoMantenimiento && estadoMantenimiento !== 'Todos') {
                query.EstadoMantenimiento = estadoMantenimiento;
            }
            
            if (placa) {
                query.Placa = placa;
            }
            
            if (cuenta) {
                query.IdNegocio = cuenta;
            }
        
            if (dateInit) {
                query.FechaInicio = dateInit.toISOString().split('T')[0];
            }
            
            if (dateFin) {
                query.FechaFin = dateFin.toISOString().split('T')[0];
            }
        
            find(query.EstadoMantenimiento, query.Placa, query.IdNegocio, query.FechaInicio, query.FechaFin);
        };
        
        
        React.useEffect(() => {
            if (tipoAlmacen === "Almacen-Proveedor" || tipoAlmacen === "Almacen-Cliente" || tipoAlmacen === "Todos" || tipoAlmacenNuevo==='Todos') {
                fetchCuentasOptions()
            }
        }, [tipoAlmacen])
        React.useEffect(() => {
            const fetchConteoEstados = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/conteo-estados`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany,
                            'Cache-Control': 'no-cache', 
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        }
                    });
                    const data = await response.json();
                    
                    const estadoStats = {
                        NO_INICIADO: 0,
                        PROGRAMADO: 0,
                        EN_PROCESO: 0,
                        PENDIENTE_POR_CONFIRMAR: 0,
                        EJECUTADO: 0,
                        TODOS:0,
                    };
        
                    data.forEach((item) => {
                        switch (item.estado) {
                            case 'No Iniciado':
                                estadoStats.NO_INICIADO = item.cantidad;
                                break;
                            case 'Programado':
                                estadoStats.PROGRAMADO = item.cantidad;
                                break;
                            case 'En Proceso':
                                estadoStats.EN_PROCESO = item.cantidad;
                                break;
                            case 'Pendiente por Confirmar':
                                estadoStats.PENDIENTE_POR_CONFIRMAR = item.cantidad;
                                break;
                            case 'Finalizado':
                                estadoStats.FINALIZADO = item.cantidad;
                                break;
                            case 'Todos':
                                estadoStats.TODOS = item.cantidad;
                                break;
                            default:
                                break;
                        }
                    });
        
                    setStats(estadoStats);
                } catch (error) {
                    console.error('Error al obtener los conteos de estados:', error);
                }
            };
        
            fetchConteoEstados();
        }, [token, tokenCompany]);
        
        const handleOpenFind = () => {
            if (almacen) {
                setOpenFind(true);
            }
        }
        const handleSelectedArticle = (article) => {
            console.log("ARTICULO + " + JSON.stringify(article));
            if (article.Stock <= 0) {
                setOpenWarningModal(true);
            } else {
                setArticulo({
                    IdAlmacen: article.IdAlmacen,
                    CodArticle: article.CodArticulo,
                    IdArticle: article.IdArticulo,
                    Article: article.Articulo,
                    Stock: article.Stock,
                });
            }
            setOpenFind(false); 
        };

        const columnsPickSerie = [
            { field: 'Placa', headerName: 'Placa', width: 400 },
        ];

        const handleGuardar = () => {
            console.log(archivo);
            const mantenimientoData = {
                TipoAlmacen: tipoAlmacen,
                IdNegocio: cuenta,
                IdAlmacen: almacen,
                IdArticulo: articulo.IdArticle,
                Descripcion: articulo.Article, 
                Comentarios: comentarios,
                FechaCreacion: new Date().toISOString(), 
                TipoMantenimiento: tipoMantenimiento,
                Placa: serie.Placa
            };
        
            fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    'Cache-Control': 'no-cache', 
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                body: JSON.stringify(mantenimientoData)
            })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(errorData.message || 'Error al crear el mantenimiento.');
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log("Mantenimiento creado:", data);
                setOpenModal(false);
                find();
                setTipoAlmacen('');
                setCuenta('');
                setAlmacen('');
                setArticulo({
                    CodArticle: '',
                    IdArticle: '',
                    Article: '',
                    IdAlmacen: ''
                });
                setComentarios('');
                setTipoMantenimiento('');
                setSerie({
                    IdControlSerie: null,
                    SerialNumber: '',
                    Placa: ''
                });
            })
            .catch(error => {
                console.error("Error al crear el mantenimiento:", error);
                setErrorMessage(error.message);
            });
        };
        
        const handleSelectedSerie = (selectedSerie) => {
            setSerie({
                IdControlSerie: selectedSerie.id,
                SerialNumber: selectedSerie.SerialNumber,
                Placa: selectedSerie.Placa
            });
        };

       
        
        const find = React.useCallback((estadoMantenimiento, placa, cuenta, fechaInicio, fechaFin) => {
            const query = new URLSearchParams();

            if (estadoMantenimiento && estadoMantenimiento !== 'Todos') {
                query.append('EstadoMantenimiento', estadoMantenimiento);
            }
            
            if (placa) {
                query.append('Placa', placa);
            }

            if (cuenta) {
                query.append('IdNegocio', cuenta);
            }

            if (fechaInicio) {
                query.append('FechaInicio', fechaInicio);
            }

            if (fechaFin) {
                query.append('FechaFin', fechaFin);
            }

            const url = `${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias?${query.toString()}`;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                    'Cache-Control': 'no-cache', 
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            })
            .then(res => res.json())
            .then(response => {
                setHeades(response); 
            })
            .catch(error => console.error('Error:', error));
        }, [token, tokenCompany]);

        React.useEffect(() => {
            find();
        }, [find]);

        const columns = [
            { field: 'correlativo', headerName: 'Número Solicitud', flex: 0.5, headerAlign: 'center', align: 'center' },
            { field: 'usuarioCreacion', headerName: 'Supervisor', flex: 0.5, headerAlign: 'center', align: 'center' },
            { field: 'negocio', headerName: 'Cliente', flex: 0.6, headerAlign: 'center', align: 'center' },
            { field: 'almacen', headerName: 'Almacén', flex: 0.6, headerAlign: 'center', align: 'center' },
            { field: 'articulo', headerName: 'Maquina', flex: 1, headerAlign: 'center', align: 'center' },
            { field: 'placa', headerName: 'Placa', flex: 0.6, headerAlign: 'center', align: 'center' },
            { field: 'fechaSolicitud', headerName: 'Fecha Solicitud', flex: 0.7, headerAlign: 'center', align: 'center' },
            {
                field: 'estadoSolicitud',
                headerName: 'Estado Solicitud',
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            fontSize: '12px',  
                            padding: '4px',   
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden',   
                            textOverflow: 'ellipsis',
                            maxWidth: '80px',  
                        }}
                    >
                        {params.row.estadoSolicitud}
                    </Typography>
                ),
            },
            
            {
                field: 'registrarInspeccion',
                headerName: 'Acciones',
                flex: 0.8,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    const { estadoSolicitud, comentarioFinalizado } = params.row; 
                    console.log("DETALLEEEEEEEEEEEEE"+ JSON.stringify(params.row))
                    if(estadoSolicitud==='De Baja'||estadoSolicitud === 'Cancelado' || estadoSolicitud === 'Finalizado'){
                        return(
                            <IconButton
                                color="primary"
                                aria-label="Detalle"
                                onClick={() => handleOpenFindModalComentarios(true, params.row.id,null, comentarioFinalizado,estadoSolicitud)} 
                            >
                                <LinkIcon />
                            </IconButton>
                        );
                    }
                    if (estadoSolicitud === 'Pendiente Por Confirmar') {
                        return (
                            <Box display="flex" justifyContent="center" gap={1}>
                                <Tooltip title="Aprobar">
                                    <IconButton
                                        onClick={() => {
                                            setSelectedRow(params.row);
                                            setOpenModalAprobar(true);
                                        }}
                                        sx={{
                                            backgroundColor: 'green', 
                                            color: 'white', // Color del icono
                                            '&:hover': {
                                                backgroundColor: 'darkgreen', // Verde más oscuro al hacer hover
                                            },
                                            padding: '2px', // Espaciado interior más ajustado
                                            borderRadius: '50%', // Botón circular
                                        }}
                                    >
                                        <CheckCircleIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                    
                                <Tooltip title="Dar de Baja">
                                    <IconButton
                                        onClick={() => {
                                            setSelectedRow(params.row);
                                            setOpenModalRechazar(true);
                                        }}
                                        sx={{
                                            backgroundColor: 'red', 
                                            color: 'white', 
                                            '&:hover': {
                                                backgroundColor: 'darkred',
                                            },
                                            padding: '2px', 
                                            borderRadius: '50%', 
                                        }}
                                    >
                                        <CancelIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        );
                    }
                    
                    
                     else {
                        const solicitudPendiente = params.row.DetalleMantenimientoMaquinaria.some(
                            (detalle) => detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Pendiente Programar' ||
                                         detalle.SolicitudTransferencium?.Parametro?.Nombre === 'En Proceso'
                        );
                        return (
                            <Box display="flex" justifyContent="center" gap={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size='small'
                                    onClick={() => {
                                        if (solicitudPendiente) {
                                            setErrorModalMessage('La máquina se encuentra en proceso para el evento "Transferencia"');
                                            setOpenErrorModal(true);
                                        } else {
                                            handleOpenModalRegistrar(params.row);
                                        }
                                    }}>
                                    Evento
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size='small'
                                    onClick={() => {
                                        setSelectedRow(params.row);
                                        setOpenModalCancelar(true);
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Box>
                        );
                    }
                }
            },
            {
                field: 'expand',
                headerName: '',
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpenRowIndex(openRowIndex === params.row.id ? null : params.row.id);
                        }}
                    >
                        {openRowIndex === params.row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                ),
            },
        ];
        
        
        const handleEstadoMantenimiento = (nuevoEstado, row, comentarioFinalizado = '') => {
            const body = {
                IdMantenimiento: row.id,
                nuevoEstado
            };
        
            if (nuevoEstado === 'Rechazar'||nuevoEstado==='Cancelar'||nuevoEstado==='Aprobar') {
                body.comentarioFinalizado = comentarioFinalizado;
            }
        
            fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/modificar-estado`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    token: token,
                    empresa: tokenCompany,
                },
                body: JSON.stringify(body)
            })
            .then(res => res.json())
            .then(response => {
                console.log('Estado actualizado:', response);
                find(); 
            })
            .catch(error => {
                console.error('Error al actualizar el estado:', error);
            });
        };
        
        

        const handleOpenModalRegistrar = (row) => {
            const ultimoTecnicoAsignado = row.DetalleMantenimientoMaquinaria?.length > 0 
        ? row.DetalleMantenimientoMaquinaria[row.DetalleMantenimientoMaquinaria.length - 1].Tecnico 
        : ''; 
        setSelectedTechnician(ultimoTecnicoAsignado || '');
            setSelectedRowData(row);
            setOpenRegistrarModal(true);
        };
        const handleEliminarDetalle = async (IdDetalleMantenimiento) => {
            if (window.confirm("¿Estás seguro de que deseas eliminar este detalle?")) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/detalle/${IdDetalleMantenimiento}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            token: token,
                            empresa: tokenCompany,
                        }
                    });
        
                    if (response.ok) {
                        find(); 
                    } else {
                        console.error('Error al eliminar el detalle');
                    }
                } catch (error) {
                    console.error('Error al eliminar el detalle:', error);
                }}}
                const handleCloseModalRegistrar = () => {
                    setSelectedTechnician('');
                    setEstadoMantenimiento('');
                    setComentariosRegistro('');
                    setAttachedFile(null);
                    setOpenRegistrarModal(false);
                };

        const handleCloseModal = () => {
            resetFields();
            setOpenModal(false);
        };
        const handleTechnicianChange = (event) => {
            setSelectedTechnician(event.target.value);
        };

        const resetFields = () => {
            setTipoMantenimiento('');
            setTipoAlmacen('');
            setCuenta('');
            setAlmacen('');
            setArticulo({
                CodArticle: '',
                IdArticle: '',
                Article: '',
                IdAlmacen: ''
            });
            setComentarios('');
            setSerie({
                IdControlSerie: null,
                SerialNumber: '',
                Placa: ''
            });
            setArchivo(null);
        };
        
        const handleSaveRegistrar = async () => {
            try {
                const detalleData = {
                    IdMantenimiento: selectedRowData.id,
                    FechaRegistro: inspectionDate.toISOString(),
                    NumeroSolicitud: selectedRowData.correlativo,
                    TipoMantenimiento: selectedRowData.tipoMantenimiento,
                    Tecnico: selectedTechnician,
                    AlmacenOrigen: selectedRowData.almacen,
                    AlmacenDestino: almacen,
                    EstadoMantenimiento: estadoMantenimiento,
                    ComentariosRegistro: comentariosRegistro,
                };
        
                if (attachedFile) {
                    const formData = new FormData();
                    formData.append('attached', attachedFile);  
        
                    const response = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/upload-file`, {
                        method: 'POST',
                        headers: {
                            'token': token,
                            'empresa': tokenCompany
                        },
                        body: formData
                    });
        
                    const result = await response.json();
        
                    if (!response.ok) {
                        throw new Error(result.message || "Error al subir el archivo.");
                    }
        
                    detalleData.archivo = result.file;
                }
        
                const saveResponse = await fetch(`${process.env.REACT_APP_API}business/api/mantenimiento-maquinarias/detalle`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        token: token,
                        empresa: tokenCompany,
                        'Cache-Control': 'no-cache', 
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                    body: JSON.stringify(detalleData)
                });
        
                const saveResult = await saveResponse.json();
        
                if (!saveResponse.ok) {
                    throw new Error(saveResult.message || "Error al guardar el detalle.");
                }
        
                console.log("Detalle guardado:", saveResult);
                setErrorMessage(null);
                handleCloseModalRegistrar();
                find(); 
                setSelectedTechnician('');
                setEstadoMantenimiento('');
                setComentariosRegistro('');
                setAttachedFile(null);
            } catch (error) {
                console.error("Error al guardar el detalle:", error);
                setErrorMessage(error.message || "Error al guardar el detalle.");
            }
        };
        
        const handleCloseWarningModal = () => {
            setOpenWarningModal(false); 
        };
        const rows = heades.map((head, index) => {
            const formattedCorrelativo = `SOL-MANT-${String(head.Correlativo).padStart(6, '0')}`;
            const formattedFechaSolicitud = head.FechaCreacion 
            ? new Date(head.FechaCreacion).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }) 
            : 'Fecha no disponible';
            return {
                id: head.id,
                correlativo: formattedCorrelativo, 
                negocio: head.TipoNegocio ? head.TipoNegocio.Nombre : '', 
                almacen: head.Almacene.Nombre,
                articulo: head.Articulo.ItemName,
                fechaSolicitud: formattedFechaSolicitud, 
                usuarioCreacion: head.Usuario.Nombres,
                descripcion: head.Descripcion,
                comentarios: head.Comentarios,
                tipoAlmacen: head.TipoAlmacen,
                tipoMantenimiento: head.TipoMantenimiento,
                placa: head.Placa,
                comentarioFinalizado: head.ComentarioFinalizado,
                estadoSolicitud: head.EstadoMantenimiento === 'Rechazado' ? 'De Baja' : head.EstadoMantenimiento, 
                DetalleMantenimientoMaquinaria: head.DetalleMantenimientoMaquinaria || []
            };
        });
        

        
        const ExpandedRow = ({ row }) => (
            <TableRow>
                <TableCell colSpan={columns.length} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={openRowIndex === row.id} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>Fecha Registro</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>Tipo de Mantenimiento</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>Técnico</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>Almacén Origen</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>Almacén Destino</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>Evento</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>Número Solicitud</TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: '#fafafa' , width: '180px'}}>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(row.DetalleMantenimientoMaquinaria) && row.DetalleMantenimientoMaquinaria.length > 0 ? (
                                        row.DetalleMantenimientoMaquinaria.map((detalle, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>
                                                {new Date(detalle.FechaRegistro).toLocaleDateString('es-ES')}
                                            </TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>{detalle.TipoMantenimiento}</TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>{detalle.Tecnico}</TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>{detalle.AlmacenOrigen}</TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>
                                                {detalle.AlmacenDestinoData ? detalle.AlmacenDestinoData.Nombre : (detalle.AlmacenDestino || '-')}
                                                </TableCell>
                                                <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>
                                                {detalle.EstadoMantenimiento}
                                                {detalle.EstadoMantenimiento === 'Transferencia' && detalle.SolicitudTransferencium?.Parametro?.Nombre && (
                                                    <Box sx={{ color: 'green', mt: 1 }}>
                                                        {detalle.SolicitudTransferencium.Parametro.Nombre}
                                                    </Box>
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>
                                        {detalle.SolicitudTransferencium ? (
                                            `SOLTRAN-${detalle.SolicitudTransferencium.Periodo}-${String(detalle.SolicitudTransferencium.Correlativo).padStart(6, '0')}`
                                        ) : (
                                            ''  
                                        )}
                                        </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor: '#fafafa' }}>
                                                {detalle.EstadoMantenimiento === 'Transferencia' && !detalle.SolicitudTransferencium?.Parametro?.Nombre && (
                                        <Tooltip title="Realizar Transferencia">
                                            <span>
                                                    <IconButton
                                                    color="primary"
                                                    disabled={row.estadoSolicitud === 'Cancelado' || row.estadoSolicitud === 'De Baja' || row.estadoSolicitud === 'Finalizado'} 
                                                    onClick={() => {

                                                        navigate('/request/transferenciaAlmacen', {
                                                        state: {
                                                            tipoTransferenciaMantenimiento: 'Mantenimiento',  
                                                            idTipoTransferenciaMantenimiento: 'a64a0913-6bc9-11ef-ba28-12c11c8bfd0f', 
                                                            tipoAlmacenOrigen: detalle.TipoAlmacen,
                                                            cuentaAreaOrigen: detalle.TipoNegocio?.IdNegocio, 
                                                            cuentaAreaOrigenArticulo: detalle.Articulo?.GrupoArticulo?.IdNegocio,
                                                            nombreCuentaAreaOrigen: detalle.TipoNegocio?.Nombre,
                                                            almacenOrigen: detalle.IdAlmacen,
                                                            nombreAlmacenOrigen: detalle.AlmacenOrigen, 
                                                            articuloOrigen: detalle.Articulo?.IdArticulo, 
                                                            nombreArticulo: detalle.Articulo?.ItemName ,
                                                            codigoArticulo: detalle.Articulo?.ItemCode,
                                                            idDetalleMantenimiento: detalle.IdDetalleMantenimiento  
                                                        }
                                                        });
                                                    }}
                                                    size="small"
                                                    >
                                                <ArrowForwardIcon />
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                    )}

                                                    <IconButton
                                                        aria-label="ver adjunto"
                                                    >
                                                        <FilePresentIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="Detalle"
                                                        onClick={() => handleOpenFindModalComentarios(true, detalle.IdDetalleMantenimiento,detalle.SolicitudTransferencium?.Parametro?.Nombre,null,null)} 
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                    <Tooltip title="Eliminar">
                                                    <span>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => {
                                                                if (
                                                                    detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Pendiente Aprobacion' ||
                                                                    detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Entregado' ||
                                                                    detalle.SolicitudTransferencium?.Parametro?.Nombre === 'Finalizado'
                                                                ) {
                                                                    setOpenErrorModal(true);
                                                                } else {
                                                                    handleEliminarDetalle(detalle.IdDetalleMantenimiento);
                                                                }
                                                            }}
                                                            size="small"
                                                            disabled={row.estadoSolicitud === 'Cancelado' || row.estadoSolicitud === 'De Baja' || row.estadoSolicitud === 'Finalizado'}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center" colSpan={7} sx={{ backgroundColor: '#fafafa' }}>
                                                No hay detalles disponibles
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        );



        const exportToExcel = () => {
            const combinedData = [];
        
            heades.forEach((head) => {
                if (head.DetalleMantenimientoMaquinaria.length > 0) {
                    head.DetalleMantenimientoMaquinaria.forEach((detalle) => {
                        combinedData.push({
                            "Número Solicitud": `SOL-MANT-${String(head.Correlativo).padStart(6, '0')}`,
                            "Supervisor": head.Usuario?.Nombres || 'N/A',
                            "Cliente": head.TipoNegocio?.Nombre || 'N/A',
                            "Almacén": head.Almacene?.Nombre || 'N/A',
                            "Maquina": head.Articulo?.ItemName || 'N/A',
                            "Placa": head.Placa || 'N/A',
                            "Fecha Solicitud": new Date(head.FechaCreacion).toLocaleDateString('es-ES'),
                            "Estado Solicitud": head.EstadoMantenimiento || 'N/A',
                            "Fecha Registro": new Date(detalle.FechaRegistro).toLocaleDateString('es-ES'),
                            "Tipo de Mantenimiento": detalle.TipoMantenimiento || 'N/A',
                            "Técnico": detalle.Tecnico || 'N/A',
                            "Almacén Origen": detalle.AlmacenOrigen || 'N/A',
                            "Almacén Destino": detalle.AlmacenDestinoData?.Nombre || 'N/A',
                            "Evento": detalle.EstadoMantenimiento || 'N/A',
                        });
                    });
                } else {
                    combinedData.push({
                        "Número Solicitud": `SOL-MANT-${String(head.Correlativo).padStart(6, '0')}`,
                        "Supervisor": head.Usuario?.Nombres || 'N/A',
                        "Cliente": head.TipoNegocio?.Nombre || 'N/A',
                        "Almacén": head.Almacene?.Nombre || 'N/A',
                        "Maquina": head.Articulo?.ItemName || 'N/A',
                        "Placa": head.Placa || 'N/A',
                        "Fecha Solicitud": new Date(head.FechaCreacion).toLocaleDateString('es-ES'),
                        "Estado Solicitud": head.EstadoMantenimiento || 'N/A',
                        "Fecha Registro": 'N/A',
                        "Tipo de Mantenimiento": 'N/A',
                        "Técnico": 'N/A',
                        "Almacén Origen": 'N/A',
                        "Almacén Destino": 'N/A',
                        "Evento": 'N/A',
                    });
                }
            });
        
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(combinedData);
        
            XLSX.utils.book_append_sheet(wb, ws, 'Mantenimientos');
        
            XLSX.writeFile(wb, 'Mantenimientos.xlsx');
        };
        

        return (
            <Container fixed>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={4}>
                        <Typography variant='h2' sx={{ color: theme.palette.primary.main }}>Mantenimientos</Typography>
                    </Grid>
                    <Grid item xs={8} marginTop={1} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        size='medium'
                        sx={{ marginRight: '30px' }}
                        onClick={() => setOpenModal(true)}
                    >
                        Nuevo
                    </Button>
                </Grid>
                    <Grid item xs={12} marginBottom={1} marginTop={1}>
                    <Grid container spacing={gridSpacing} alignItems="center">
                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <CardWrapper border={false} content={false}>
                        <StatsCard
                            title="No Iniciado"
                            color={theme.palette.warning.main}
                            number={stats["NO_INICIADO"]}
                            onClick={() => find("No Iniciado")}
                        />
                    </CardWrapper>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <CardWrapper border={false} content={false}>
                        <StatsCard
                            title="Programado"
                            color={theme.palette.primary.main}
                            number={stats["PROGRAMADO"]}
                            onClick={() => find("Programado")}
                        />
                    </CardWrapper>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <CardWrapper border={false} content={false}>
                        <StatsCard
                            title="En Proceso"
                            color="#4CAF50"
                            number={stats["EN_PROCESO"]}
                            onClick={() => find("En Proceso")}
                        />
                    </CardWrapper>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <CardWrapper border={false} content={false}>
                        <StatsCard
                            title="Por Confirmar"
                            color={theme.palette.info.main}
                            number={stats["PENDIENTE_POR_CONFIRMAR"]}
                            onClick={() => find("Pendiente Por Confirmar")}
                        />
                    </CardWrapper>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <CardWrapper border={false} content={false}>
                        <StatsCard
                            title="Finalizado"
                            color="#B0B0B0"
                            number={stats["FINALIZADO"]}
                            onClick={() => find("Finalizado")}
                        />
                    </CardWrapper>
                </Grid>
            </Grid>

                    <Grid container spacing={2} alignItems="center" marginTop={1}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel>Filtrar por Estado</InputLabel>
                                <Select
                                    value={estadoMantenimiento}
                                    onChange={(e) => {
                                        setEstadoMantenimiento(e.target.value);
                                       // find(e.target.value === 'Todos' ? null : e.target.value);
                                    }}
                                    label="Filtrar por Estado"
                                >
                                    <MenuItem value="Todos">Todos</MenuItem>
                                    <MenuItem value="No Iniciado">No Iniciado</MenuItem>
                                    <MenuItem value="Programado">Programado</MenuItem>
                                    <MenuItem value="En Proceso">En Proceso</MenuItem>
                                    <MenuItem value="Pendiente por Confirmar">Pendiente por Confirmar</MenuItem>
                                    <MenuItem value="Finalizado">Finalizado</MenuItem>
                                    <MenuItem value="Rechazado">De Baja</MenuItem>
                                    <MenuItem value="Cancelado">Cancelado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Cuenta/Área</InputLabel>
                    <Select
                        value={cuenta}
                        onChange={(e) => setCuenta(e.target.value)}
                        label="Cuenta/Área"
                    >
                        <MenuItem value="">--Seleccionar--</MenuItem>
                        {cuentaOptionsFiltro.map(option => (
                            <MenuItem
                                key={option.tipo === 'negocio' ? option.IdNegocio : option.IdProveedor}
                                value={option.tipo === 'negocio' ? option.IdNegocio : option.IdProveedor}
                            >
                                {option.Nombre}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={2}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Placa"
                    value={placa}
                    onChange={(e) => setPlaca(e.target.value)}
                />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <Grid item xs={2}>
                    <DesktopDatePicker
                        label="Fecha Inicio"
                        inputFormat="dd/MM/yyyy"
                        value={dateInit}
                        onChange={(newValue) => setDateInit(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <DesktopDatePicker
                        label="Fecha Fin"
                        inputFormat="dd/MM/yyyy"
                        value={dateFin}
                        onChange={(newValue) => setDateFin(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    />
                </Grid>
            </LocalizationProvider>
         
            <Grid item xs={1}>
                <Tooltip title="Buscar">
                    <IconButton onClick={handleSearch} color="primary">
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        
                        <Grid item xs={1} textAlign="left">
                            <Tooltip title="Exportar a Excel">
                                <IconButton
                                    size="large"
                                    onClick={exportToExcel}
                                    sx={{
                                        backgroundColor: '#4CAF50',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: theme.palette.success.dark,
                                        }
                                    }}
                                >
                                    <IconFileDownload />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    </Grid>   
                    <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.field}
                                            sx={{
                                                textAlign: 'center',
                                                whiteSpace: 'normal', 
                                                wordWrap: 'break-word', 
                                                maxWidth: '200px', 
                                            }}
                                        >
                                            {column.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <React.Fragment key={row.id}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            {columns.map((column) => (
                                                column.field === 'registrarInspeccion' || column.field === 'estadoSolicitud' || column.field === 'expand' ? (
                                                    <TableCell
                                                        key={column.field}
                                                        align="center"
                                                        sx={{
                                                            textAlign: 'center',
                                                            whiteSpace: 'nowrap',   // Mantiene el texto en una sola línea
                                                            overflow: 'hidden',     // Oculta el texto que se desborda
                                                           // textOverflow: 'ellipsis', // Muestra "..." si el texto es demasiado largo
                                                            maxWidth: '150px',      // Define un ancho máximo
                                                        }}
                                                    >
                                                        {column.renderCell({ row })}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        key={column.field}
                                                        align="center"
                                                        sx={{
                                                            textAlign: 'center',
                                                            whiteSpace: 'normal',
                                                            wordWrap: 'break-word',
                                                            maxWidth: '120px',
                                                        }}
                                                    >
                                                        {row[column.field]}
                                                    </TableCell>
                                                )
                                            ))}
                                        </TableRow>
                                        <ExpandedRow row={row} />
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                        </Grid>
                        <Modal
                                open={openErrorModal}
                                onClose={() => setOpenErrorModal(false)}
                                aria-labelledby="modal-error-title"
                                aria-describedby="modal-error-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                        borderRadius: 4,
                                        textAlign: 'center'
                                    }}
                                >
                                  <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                                bgcolor: 'red',
                                color: 'white',
                                fontSize: 40,
                                margin: '0 auto 16px auto',
                            }}
                        >
                            X
                        </Box>

                        <Typography id="modal-error-title" variant="h2" sx={{ color: 'primary.main', mb: 2 }}>
                            Error
                        </Typography>
                                    <Typography id="modal-error-description" sx={{ mb: 4 }}>
                                    La máquina se encuentra en proceso para el evento "Transferencia"                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setOpenErrorModal(false)}
                                        sx={{ width: '100px', mx: 'auto' }}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Modal>
                        <Modal
                            open={openModalRechazar}
                            onClose={() => setOpenModalRechazar(false)}
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4, 
                                textAlign: 'center'
                            }}>
                                <Typography variant="h5" mb={2}>
                                    ¿Está seguro que desea Rechazar el mantenimiento?
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Comentarios"
                                    value={comentarioRechazo}
                                    onChange={(e) => setComentarioRechazo(e.target.value)}
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    margin="normal"
                                />
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        handleEstadoMantenimiento('Rechazar', selectedRow, comentarioRechazo);
                                        setOpenModalRechazar(false);
                                    }}
                                >
                                    Rechazar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setOpenModalRechazar(false)}
                                    sx={{ ml: 2 }}
                                >
                                    Cerrar
                                </Button>
                            </Box>
                        </Modal>
                        <Modal
                        open={openModalCancelar}
                        onClose={() => setOpenModalCancelar(false)}
                    >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4, 
                                textAlign: 'center'
                            }}><Typography variant="h5" mb={2}>
                                ¿Está seguro que desea Cancelar el mantenimiento?
                            </Typography>
                            <TextField
                                fullWidth
                                label="Comentarios"
                                value={comentarioRechazo} 
                                onChange={(e) => setComentarioRechazo(e.target.value)}
                                multiline
                                rows={3}
                                variant="outlined"
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    handleEstadoMantenimiento('Cancelar', selectedRow, comentarioRechazo); 
                                    setOpenModalCancelar(false);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setOpenModalCancelar(false)}
                                sx={{ ml: 2 }}
                            >
                                Cerrar
                            </Button>
                        </Box>
                    </Modal>
                    <Modal
                    open={openModalAprobar}
                    onClose={() => setOpenModalAprobar(false)}
                >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 4, 
                                textAlign: 'center'
                            }}>                        <Typography variant="h5" mb={2}>
                            ¿Está seguro que desea Aprobar el mantenimiento?
                        </Typography>
                        <TextField
                            fullWidth
                            label="Comentarios"
                            value={comentarioRechazo} // Usamos el mismo campo de comentarios
                            onChange={(e) => setComentarioRechazo(e.target.value)}
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleEstadoMantenimiento('Aprobar', selectedRow, comentarioRechazo); // Enviar comentarioFinalizado
                                setOpenModalAprobar(false);
                            }}
                        >
                            Aprobar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpenModalAprobar(false)}
                            sx={{ ml: 2 }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Modal>
                <Modal
                    open={!!errorMessage}
                    onClose={() => setErrorMessage(null)}
                    aria-labelledby="modal-error-title"
                    aria-describedby="modal-error-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 4, 
                            textAlign: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                                bgcolor: 'red',
                                color: 'white',
                                fontSize: 40,
                                margin: '0 auto 16px auto',
                            }}
                        >
                            X
                        </Box>

                        <Typography id="modal-error-title" variant="h2" sx={{ color: 'primary.main', mb: 2 }}>
                            Error
                        </Typography>

                        <Typography id="modal-error-description" sx={{ mb: 4 }}>
                            {errorMessage}
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setErrorMessage(null)}
                            sx={{ width: '100px', mx: 'auto' }}
                        >
                            OK
                        </Button>
                    </Box>
                </Modal>


                        <Modal
                    open={openWarningModal}
                    onClose={handleCloseWarningModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 420,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            textAlign: 'center',
                        }}
                    >
                        <Typography id="modal-title" variant="h3" component="h2">
                            Artículo sin stock
                        </Typography>
                        <Typography id="modal-description" variant="h4" sx={{ mt: 3 }}>
                            El artículo seleccionado no tiene stock disponible.
                        </Typography>
                        <Button
                            onClick={handleCloseWarningModal}
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Modal>
                        <Modal
                            open={openModalComentarios}
                            onClose={() => setOpenModalComentarios(false)}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Box
                                sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 800,
                                height: "52vh",
                                bgcolor: "background.paper",
                                p: 4,
                                overflowY: "auto",
                                paddingBottom: "16px",
                                boxShadow: 10, 
                                borderRadius: 4,
                                border: '1px solid #e0e0e0' 
                                }}
                            >
                                <IconButton
                                    onClick={() => setOpenModalComentarios(false)}
                                    sx={{ position: 'absolute', top: 8, right: 8 }}
                                >
                                    X
                                </IconButton>

                                <Typography id="modal-title" variant="h2"  component="h2" sx={{ mb: 2 , color: 'primary.main'}}>
                                    Detalles del Mantenimiento
                                </Typography>

                                <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Fecha Registro</TableCell>
                                            <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Comentario</TableCell>
                                            <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Evento Mantenimiento</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(detallesComentarios) && detallesComentarios.length > 0 ? (
                                            detallesComentarios.map((detalle) => (
                                                <TableRow key={detalle.IdDetalleMantenimiento}>
                                                    <TableCell sx={{ textAlign: 'center' }}>{new Date(detalle.FechaRegistro).toLocaleDateString()}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{detalle.Comentarios}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                    {detalle.EstadoMantenimiento}
                                                    {detalle.parametroNombre && (
                                                        <Box sx={{ color: 'green', mt: 1 }}>
                                                            {detalle.parametroNombre}
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                    </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ textAlign: 'center' }}>No hay detalles disponibles</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setOpenModalComentarios(false)}
                                            size='small'
                                        >
                                            Cerrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal} 
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                            >
                            <Box
                                sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 550,
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                maxHeight: "100vh",
                                overflowY: "auto",
                                }}
                            >
                                <IconButton
                                onClick={handleCloseModal} 
                                sx={{ position: "absolute", top: 8, right: 8 }}
                                >
                                X
                                </IconButton>
                                <Typography
                                id="modal-title"
                                variant="h2"
                                component="h2"
                                marginBottom={2}
                                sx={{ color: 'primary.main' }}  
                                >
                                Registro de Mantenimiento
                                </Typography>

                                <Grid container spacing={1}> 
                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel>Tipo de Mantenimiento</InputLabel>
                                    <Select
                                        value={tipoMantenimiento}
                                        onChange={(e) => setTipoMantenimiento(e.target.value)}
                                        label="Tipo de Mantenimiento"
                                    >
                                        <MenuItem value="Preventivo">Preventivo</MenuItem>
                                        <MenuItem value="Correctivo">Correctivo</MenuItem>
                                        <MenuItem value="Predictivo">Predictivo</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense"> 
                                    <InputLabel id="lblTipoAlmacen">Tipo de Almacén</InputLabel>
                                    <Select
                                        labelId="lblTipoAlmacen"
                                        label="Tipo de Almacén"
                                        value={tipoAlmacen}
                                        onChange={(event) => setTipoAlmacen(event.target.value)}
                                    >
                                        {tipoAlmacenOptions.map((option) => (
                                        <MenuItem key={option.id} value={option.valor_1 || option.nombre}>
                                            {option.nombre}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel disabled={disabledCuenta}>Cuenta/Área</InputLabel>
                                    <Select
                                        value={cuenta}
                                        onChange={(e) => setCuenta(e.target.value)}
                                        label="Cuenta/Área"
                                        disabled={disabledCuenta}
                                    >
                                        <MenuItem value="">--Seleccionar--</MenuItem>
                                        {cuentaOptions.map((option) => (
                                        <MenuItem
                                            key={
                                            option.tipo === "negocio" ? option.IdNegocio : option.IdProveedor
                                            }
                                            value={
                                            option.tipo === "negocio" ? option.IdNegocio : option.IdProveedor
                                            }
                                        >
                                            {option.Nombre}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense"> 
                                    <InputLabel>Almacén</InputLabel>
                                    <Select
                                        value={almacen}
                                        onChange={(e) => setAlmacen(e.target.value)}
                                        label="Almacén"
                                    >
                                        {almacenOptions.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense"> 
                                    <InputLabel htmlFor="txtCodArticulo">Cod. de producto</InputLabel>
                                    <OutlinedInput
                                        id="txtCodArticulo"
                                        type="text"
                                        value={articulo.CodArticle}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleOpenFind}>
                                            <Search />
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Cod. de producto"
                                    />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel htmlFor="txtSerie">Placa</InputLabel>
                                    <OutlinedInput
                                        id="txtSerie"
                                        type="text"
                                        value={`${serie.Placa}`}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="buscar serie"
                                            onClick={() => setOpenSerie(true)}
                                            edge="end"
                                            >
                                            <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Serie - Placa"
                                    />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    label="Descripción"
                                    variant="outlined"
                                    margin="dense"
                                    value={articulo.Article}
                                    disabled
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    label="Comentarios"
                                    variant="outlined"
                                    value={comentarios}
                                    onChange={(e) => setComentarios(e.target.value)}
                                    multiline
                                    rows={2}
                                    margin="dense"
                                    />
                                </Grid>

                                <Grid item xs={12} marginTop={2} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <Button
                                    variant="contained"
                                    size="medium" 
                                    component="label"
                                    >
                                    Adjuntar Archivos
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) => setArchivo(e.target.files[0])}
                                    />
                                    </Button>

                                    <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"  
                                    onClick={handleGuardar}
                                    >
                                    Guardar
                                    </Button>
                                </Grid>
                                </Grid>
                            </Box>
                            </Modal>


                            <Modal
                            open={openRegistrarModal}
                            onClose={handleCloseModalRegistrar}
                            aria-labelledby="modal-inspeccion-title"
                            aria-describedby="modal-inspeccion-description"
                            >
                            <Box
                                sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 500,
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                maxHeight: "90vh",
                                overflowY: "auto",
                                boxShadow: 10, 
                                borderRadius: 4,
                                border: '1px solid #e0e0e0' 
                                }}
                            >
                                <IconButton
                                onClick={handleCloseModalRegistrar}
                                sx={{ position: "absolute", top: 8, right: 8 }}
                                >
                                X
                                </IconButton>
                                <Typography
                                id="modal-inspeccion-title"
                                variant="h2"
                                component="h2"
                                sx={{ color: 'primary.main' }} 
                                marginBottom={2}
                                >
                                Registrar Evento
                                </Typography>

                                <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                    fullWidth
                                    label="Número de Solicitud"
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedRowData?.correlativo || ''}
                                    disabled
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                    fullWidth
                                    label="Supervisor"
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedRowData?.usuarioCreacion || ''}
                                    disabled
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                    fullWidth
                                    label="Fecha de Solicitud"
                                    variant="outlined"
                                    margin="dense"
                                    value={selectedRowData?.fechaSolicitud || ''}
                                    disabled
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                    <DesktopDatePicker
                                        label="Fecha de Registro"
                                        inputFormat="dd/MM/yyyy"
                                        value={inspectionDate}
                                        onChange={setInspectionDate}
                                        disabled={true}
                                        renderInput={(params) => (
                                        <TextField {...params} fullWidth variant="outlined" margin="dense" />
                                        )}
                                    />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel>Seleccionar Técnico</InputLabel>
                                    <Select
                                        value={selectedTechnician}
                                        onChange={handleTechnicianChange}
                                        label="Seleccionar Técnico"
                                    >
                                        <MenuItem value="Tecnico 1">Tecnico 1</MenuItem>
                                        <MenuItem value="Tecnico 2">Tecnico 2</MenuItem>
                                        <MenuItem value="Tecnico 3">Tecnico 3</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>
                                {errorMessage && (
                                    <Modal
                                        open={!!errorMessage}
                                        onClose={() => setErrorMessage(null)}
                                        aria-labelledby="modal-error-title"
                                        aria-describedby="modal-error-description"
                                    >
                                        <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 400,
                                            bgcolor: 'background.paper',
                                            boxShadow: 24,
                                            p: 4,
                                            borderRadius: 4, 
                                            textAlign: 'center'
                                        }}
                                        >
                                        <Box
                                            sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 50,
                                            height: 50,
                                            borderRadius: '50%',
                                            bgcolor: 'red',
                                            color: 'white',
                                            fontSize: 40,
                                            margin: '0 auto 16px auto', 
                                            }}
                                        >
                                            X
                                        </Box>

                                        <Typography id="modal-error-title" variant="h2" sx={{ color: 'primary.main', mb: 2 }}>
                                            Error
                                        </Typography>

                                        <Typography id="modal-error-description" sx={{ mb: 4 }}>
                                            {errorMessage}
                                        </Typography>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setErrorMessage(null)}
                                            sx={{ width: '100px', mx: 'auto' }}
                                        >
                                            OK
                                        </Button>
                                        </Box>
                                    </Modal>
                                    )}


                                <Grid item xs={6}>
                                
                                    <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel>Evento Mantenimiento</InputLabel>
                                    <Select
                                        value={estadoMantenimiento}
                                        onChange={(e) => setEstadoMantenimiento(e.target.value)}
                                        label="Estado Mantenimiento"
                                    >
                                        <MenuItem value="Programar Inspección">Programar Inspección</MenuItem>
                                        <MenuItem value="Inspección Técnica">Inspección Técnica</MenuItem>
                                        <MenuItem value="Transferencia">Transferencia</MenuItem>
                                        <MenuItem value="En Mantenimiento">En Mantenimiento</MenuItem>
                                        <MenuItem value="Mantenimiento Proveedor">Mantenimiento Proveedor</MenuItem>
                                        <MenuItem value="Pendiente Por Confirmar">Pendiente Por Confirmar</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    label="Comentarios"
                                    variant="outlined"
                                    value={comentariosRegistro}
                                    onChange={(e) => setComentariosRegistro(e.target.value)}
                                    multiline
                                    rows={2}
                                    margin="dense"
                                    />
                                </Grid>

                                <Grid item xs={12} marginTop={2} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                    <Button
                                    variant="contained"
                                    size="medium" 
                                    component="label"
                                    >
                                    Adjuntar Archivos
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) => setAttachedFile(e.target.files[0])}
                                    />
                                    </Button>

                                    <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium" 
                                    onClick={handleSaveRegistrar}
                                    >
                                    Guardar
                                    </Button>
                                </Grid>
                                </Grid>
                            </Box>
                            </Modal>

                    <PickArticle
                        open={openFind}
                        reload={true}
                        handleClose={() => { setOpenFind(false) }}
                        handleSelectedArticle={handleSelectedArticle}
                        url={`${process.env.REACT_APP_API}business/api/articulo/getArticles`}
                        param={{
                            IdNegocio: cuenta,
                            IdAlmacen: almacen
                        }}
                        columns={[
                            { field: 'Articulo', headerName: 'Producto', width: 200 },
                            { field: 'CodArticulo', headerName: 'Cod. Producto', width: 200 },
                            { field: 'NombreNegocio', headerName: 'Nombre Negocio', width: 200 },
                            { field: 'Almacen', headerName: 'Almacén', width: 150 },
                            { field: 'Categoria', headerName: 'Categoría', width: 150 },
                            { field: 'Stock', headerName: 'Stock', type: 'number', width: 90 },
                        ]}
                    />
                </Grid>
                <PickSerie
                    open={openSerie}
                    handleClose={() => setOpenSerie(false)}
                    handleSelectedArticle={handleSelectedSerie}
                    url={`${process.env.REACT_APP_API}business/api/control_series/ListByArticle`}
                    param={{
                        IdNegocio: cuenta,
                        IdAlmacen: articulo.IdAlmacen,
                        IdArticulo: articulo.IdArticle
                    }}
                    columns={columnsPickSerie}
                />
            </Container>
        )
    }

    export default MantenimientoMaquinarias;
